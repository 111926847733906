<template>
  <div class="CourseTextColl" @focusin="onFocus" @mouseenter="onFocus" @mouseleave="onFocusout" @focusout="onFocusout"
       @click="onClick"
       ref="containerRef" :class="{'checked': checked}" v-if="textList.length > 0">
    <!--    <div class="triangle" :class="`type-${textData?.courseDto?.inClass}`" ref="triangleRef"></div>-->
    <div class="course-text-coll--box" :class="`type-${getTypeName(textData?.courseDto)}`" ref="boxRef">
      <div style="display: block">{{ textData.n }} ({{getClassType(textData?.courseDto)}})</div>
      <span v-for="(i, k) in textList" :key="k">
        {{ i.topicName }}
      </span>
    </div>

    <slot>
    </slot>
  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, defineEmits, computed} from 'vue'
import {gsap} from 'gsap'

const props = defineProps({
  textData: {
    type: Object,
    default: () => {
    },
  },
  isSubject: {
    type: Boolean,
    default: false,
  }
})

const em = defineEmits(['click'])

let containerRef = ref(null)
let triangleRef = ref(null)
let boxRef = ref(null)
let checked = ref(false)
let _left = 0

let textList = computed(() => {
  let courseDto = props.textData.courseDto
  if (courseDto && courseDto.topicInfos?.length > 0) {
    let topicInfos = courseDto.topicInfos
    if (checked) {
      return topicInfos;
    } else {
      return topicInfos.splice(0)
    }
  } else {
    return []
  }
})

const getClassType = (dto) => {
  let code;
  if (props.isSubject){
    code = dto?.flag
    return code?'已上架': '未上架'
  }else{
    code = dto?.inClass
    switch (code){
      case 1:
        return '已上课'
      case 2:
        return '上课中'
      case 3:
        return '未上课'
    }
  }
}

const getTypeName = (dto) => {
  let code;
  if (props.isSubject){
    code = dto?.flag
    return code? '3': '1'
  }else{
    code = dto?.inClass
    return code
  }
}

onMounted(() => {
  if (!props.isSubject){
    getCurrentStatus()
  }
})

const onFocus = (e) => {
  checked.value = true
  currentObjectSelect()
}

const onClick = () => {
  em('click', props.textData)
}

const onFocusout = (e) => {
  checked.value = false
  currentObjectUnselect()
}

const getCurrentStatus = () => {
  if (props?.textData?.courseDto?.inClass === 2) {
    checked.value = true
    currentObjectSelect(1)
  }
}


const currentObjectSelect = (repeat) => {
  let left = containerRef.value.parentElement.style.left
  let line = 0
  textList.value.forEach(v => {
    line += Math.ceil(v.topicName.length / 11)
  })
  console.log(line);
  let height = line * 30 + textList.value.length * 20 + 40
  let t1 = gsap.timeline({
    onComplete: () => {
      if (repeat) {
        let t2 = gsap.timeline()
        t2.to(boxRef.value, {
          scale: 1,
        }).to(boxRef.value, {
          scale: 1.25,
          duration: 1
        })
      }
    }
  })
  _left = parseInt(left)
  if (_left + 240 * 1.25 > window.innerWidth) {
    t1.to(boxRef.value, {
      width: 240, height: height
    }).to(containerRef.value.parentElement, {
      left: window.innerWidth - 240 * 1.25
    }).to(boxRef.value, {
      scale: 1.25
    })
  } else {
    _left = 0
    t1.to(boxRef.value, {
      width: 240, height: height
    }).to(boxRef.value, {
      scale: 1.25
    })
  }


}

const currentObjectUnselect = () => {
  let t1 = gsap.timeline()
  if (_left !== 0) {
    t1.to(containerRef.value.parentElement, {
      left: _left
    })
  }
  t1.to(boxRef.value, {
    width: 160, height: 82
  }).to(boxRef.value, {
    scale: 1
  })
}


</script>

<style lang="less" scoped>
.CourseTextColl {
  position: relative;

  &.checked {
    .course-text-coll--box {
      white-space: initial !important;
      text-overflow: initial !important;

      span {
        padding: 10px 0;
        display: block;
      }

      div {
        padding: 0;
      }

      span + span {
        border-top: 1px dashed #FFF;
      }
    }
  }

  .triangle {
    position: absolute;
    left: -25px;
    transform: rotate(270deg);
    top: 50%;
    cursor: pointer;

    &:after {
      content: '';
      border-style: solid;
      border-width: 0 10px 10px;
      border-color: transparent transparent #B5B5B5;
    }

    &.type-1:after {
      border-color: transparent transparent #B5B5B5;
    }

    &.type-2:after {
      border-color: transparent transparent #E53624;
    }

    &.type-3:after {
      border-color: transparent transparent #13CF73;
    }
  }

  .course-text-coll--box {
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-height: 30px;
    white-space: nowrap;
    font-size: 20px;
    cursor: pointer;
    padding: 10px 5px;
    border-radius: 10px;
    background-color: #000;
    color: #FFFFFF;

    div {
      padding: 0;
    }

    span {
      padding: 0;
    }

    &.type-1 {
      background-color: #B5B5B5;
      border: 3px solid #FFF;
    }

    &.type-2 {
      background-color: #E53624;
      border: 3px solid #FFF;
    }

    &.type-3 {
      background-color: #13CF73;
      border: 3px solid #FFF;
    }
  }
}
</style>
